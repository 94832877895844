import { NgStyle } from '@angular/common';
import { booleanAttribute, Component, computed, HostBinding, inject, input, numberAttribute } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { TButtonAlign, TButtonSize, TButtonType } from '@shared/components/button/button';
import { TBackgroundColor, TColor } from '@shared/interfaces';
import { AppStyles } from '@shared/services/app-styles.service';

@Component({
    selector: 'app-button',
    standalone: true,
    imports: [MatIcon, MatButton, MatIconButton, TranslateModule, MatBadgeModule, NgStyle],
    template: `
        <button
            mat-button
            [class.mat-button-icon]="iconOnly()"
            [class.align--left]="align() === 'left'"
            [class.align--right]="align() === 'right'"
            [class.icon--right]="iconAlign() === 'right'"
            [disabled]="disabled()"
            [class.size--small]="size() === 'small'"
            [class.size--medium]="size() === 'medium' || !size()"
            [class.size--custom]="size() === 'custom'"
            [class.width--full]="fullWidth()"
            [class.type--primary]="type() === 'primary' || !type()"
            [class.type--secondary]="type() === 'secondary'"
            [class.type--tertiary]="type() === 'tertiary'"
            [class.type--transparent]="type() === 'transparent'"
            [class.type--fab]="type() === 'fab'"
            [class.type--fab-mini]="type() === 'fab-mini'"
            [class.state--loading]="loading()"
            [class.icon-color]="!!iconColor()"
            [style.background-color]="backgroundColor()"
            [style.color]="textColor()">
            @if (!loading()) {
                @if (notificationDot()) {
                    <div class="notification-dot"></div>
                }

                @if (icon()) {
                    <mat-icon [matBadge]="badge()" matBadgeSize="small">{{ icon() }}</mat-icon>
                }
                {{ label() | translate }}
                <ng-content></ng-content>
            }
        </button>
    `,
    styles: `
        :host {
            &.disabled {
                pointer-events: none;
            }

            button {
                transition: all var(--theme-duration-standard);
                outline: none;

                &.state--loading ::ng-deep .mdc-button__label:before {
                    margin-right: 0 !important;
                }

                &.state--loading ::ng-deep .mdc-button__label:after {
                    margin-left: 0 !important;
                }

                &.icon--right ::ng-deep .mdc-button__label {
                    flex-direction: row-reverse;
                }

                &.align--left ::ng-deep .mdc-button__label {
                    width: 100%;
                    justify-content: left;
                }

                &.align--right ::ng-deep .mdc-button__label {
                    width: 100%;
                    justify-content: right;
                }

                &:not(.icon-color) {
                    &:hover icon ::ng-deep mat-icon {
                        color: inherit !important;
                    }
                }

                &.icon-color {
                    &:hover {
                        background: var(--hover-color) !important;
                        border-color: var(--hover-color) !important;
                    }
                }

                &.size--custom {
                    width: 38px !important;
                    height: 38px !important;
                }

                .notification-dot {
                    --dot: 7px;
                    width: var(--dot);
                    height: var(--dot);
                    background-color: var(--color-primary);
                    position: absolute;
                    right: -6px;
                    top: 1px;
                    border-radius: 10px;
                }

                ::ng-deep .mdc-button__label {
                    white-space: normal !important;
                    line-height: 1 !important;
                }
            }
        }
    `,
})
export class ButtonComponent {
    private appStyles: AppStyles = inject(AppStyles);

    icon = input<string>();
    label = input<string>('');
    badge = input<string | null>(null);
    color = input<TBackgroundColor>();
    iconColor = input<TColor>();
    customColor = input<string>(''); // overwrites color
    customTextColor = input<string>(''); // overwrites color
    iconCustomColor = input<string>(''); // overwrites icon color
    size = input<TButtonSize | undefined>(undefined);
    iconSize = input<number, unknown>(this.appStyles.getNumberFromPixels('iconSize'), {
        transform: numberAttribute,
    });
    type = input<TButtonType | undefined>(undefined);
    align = input<TButtonAlign | undefined>(undefined);
    iconAlign = input<TButtonAlign>('left');
    fullWidth = input<boolean, unknown>(false, { transform: booleanAttribute });
    disabled = input<boolean, unknown>(false, { transform: booleanAttribute });
    iconOnly = input<boolean, unknown>(false, { transform: booleanAttribute });
    loading = input<boolean, unknown>(false, { transform: booleanAttribute });
    notificationDot = input<boolean, unknown>(false, { transform: booleanAttribute });

    backgroundColor = computed(() => {
        if (this.type() && this.disabled()) return '';
        if (this.customColor()) return this.customColor();
        if (this.color()) return this.appStyles.getBackgroundColor(this.color());
        return '';
    });

    textColor = computed(() => {
        if (this.type() && this.disabled()) return '';
        if (this.customTextColor()) return this.customTextColor();
        return '';
    });

    hoverColor = computed(() =>
        this.iconColor() ? this.appStyles.toOpacity(undefined, this.appStyles.getColor(this.iconColor()), 0.2) : '',
    );

    @HostBinding('style.--hover-color')
    get _buttonHoverColor() {
        return this.hoverColor();
    }

    @HostBinding('class.disabled')
    get disabledClass() {
        return this.disabled();
    }

    @HostBinding('class.width-100')
    get width100() {
        return this.fullWidth();
    }
}
