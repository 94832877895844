import { Location } from '@angular/common';
import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { TPageType } from '@shared/interfaces/route.interface';
import { AppRouteService } from '@shared/services/app-route.service';
import { INavigationOptions } from '@shared/services/navigation-options.interface';
import { ToastService } from '@shared/services/toast.serivce';
import { UserService } from '@shared/services/user.service';
import { translations } from '@shared/utils/translations';
import { filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppNavigationService {
    protected readonly translations = translations;

    private router: Router = inject(Router);
    private location: Location = inject(Location);
    private appRouteService: AppRouteService = inject(AppRouteService);
    private userService: UserService = inject(UserService);
    public toastService: ToastService = inject(ToastService);

    loadingLogout: WritableSignal<boolean> = signal(false);

    defaultRoutePath: WritableSignal<string> = signal(this.appRouteService.getPath(this.appRouteService.defaultRoute));

    previousStateFrom: Signal<string> = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            map(() => {
                const navigation = this.router.getCurrentNavigation();
                return navigation?.extras.state ? navigation?.extras.state['from'] : '';
            }),
        ),
        { initialValue: '' },
    );

    previousRoute = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            map(() => this.router.url),
        ),
        { initialValue: '' },
    );

    currentRoute = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.router.url.split('?')[0]),
        ),
        { initialValue: '' },
    );

    navigationStart = toSignal(this.router.events.pipe(map(event => event instanceof NavigationStart)), {
        initialValue: false,
    });

    navigationEnd = toSignal(this.router.events.pipe(map(event => event instanceof NavigationEnd)), {
        initialValue: false,
    });

    hasBackButton = computed(() => !this.appRouteService.getMenuRoutes().some(r => r === this.currentRoute()));

    constructor() {
        // mobile
        App.addListener('backButton', () => {
            if (this.hasBackButton()) {
                this.goBack();
            } else {
                App.exitApp();
            }
        });

        window.addEventListener('popstate', () => {
            this.goBack(true);
        });
    }

    navigateTo(page: TPageType, options?: Partial<INavigationOptions>) {
        let commands: string[] = [this.appRouteService.getPath(page)];

        if (options?.parameter) commands = commands.concat(options?.parameter.split('/'));

        this.router.navigate(commands, {
            state: {
                from: options?.from,
            },
            replaceUrl: options?.from == 'image' ? false : !!options?.from,
        });
    }

    goBack(skipGoBack?: boolean) {
        if (!skipGoBack) {
            if (window.history.length === 1) this.navigateTo('settings');
            else this.location.back();
        }
    }

    async logout(hideToast?: boolean): Promise<void> {
        this.loadingLogout.set(true);

        this.userService.logoutUser().then(
            () => {
                this.loadingLogout.set(false);
            },
            err => {
                if (!hideToast) this.toastService.error(this.translations.toast.error.auth.logout);
            },
        );
    }

    getUrl(page: TPageType, options?: Partial<INavigationOptions>): string {
        let commands: string[] = [this.appRouteService.getPath(page)];
        if (options?.parameter) commands = commands.concat(options?.parameter.split('/'));
        return this.router
            .createUrlTree(commands, {
                queryParams: this.filteredQueryParams(options?.queryParams),
            })
            .toString();
    }

    updateUrl(page: TPageType, options?: Partial<INavigationOptions>): void {
        this.location.replaceState(this.getUrl(page, options));
    }

    private filteredQueryParams(queryParams: any) {
        return Object.fromEntries(Object.entries(queryParams || {}).filter(entry => entry[1] !== ''));
    }
}
